
// import React, { useState } from 'react';
// import logo from './logo.svg';
import './App.css';
// import ReactDOM from "react-dom";
import HeaderC from './components/HeaderC';
 import Sidebar from './components/Sidebar';
 import ContentArea from './components/ContentArea';
 import Footer from './components/Footer'
//  import Entry from './components/Entry';
//  import AllEntries from "./components/AllEntries";




function App() {
return(
<>
<HeaderC/>
<Sidebar/>
<ContentArea/>
<Footer/>

head
  <div>Test</div>
  </>

//   const [State,SetState] = useState();
//   const [Card,AddCard] = useState([]);

//   const [dark,change]=useState("black");
 
//   function ChangeState(event){
//     SetState(event.target.value);

// }

// const addcard = () => {

// AddCard((olddata)=>
// {
// return [...olddata,Card]
// }
// )
// }


// function darkmode(){
  
//   color = document.getElementsByClassName("itisdarkmode");
//  console.log(color);
// if(color){
// console.log("Checked");
// color=1;
// }
// else{console.log("Un Checked");}
// }

//   return (
// <div id='complete'>
// Testingjsdfk
//   <button onClick={darkmode}>Dark Mode</button>

// <h1>{State}</h1>
 
// <input type="text" onChange={ChangeState}/>

// <button onClick={addcard}>Add</button>

// <label class="switch">
//   <input />
//   <span class="slider round"></span>
//   <button onClick={document.getElementById("complete").style.backgroundColor='green'} className='itisdarkmode' type="checkbox" >Dark</button>
// </label>

// <ol>

// {Card.map( (cards) => {

// return <li>{cards}</li>
// })
// }

// </ol>
//  {/*  <Entry link="https://source.unsplash.com/200x200/?avatar" name="Mic" content="I am a Graphic Designer" email="Mic@gmail.com" phoneno="02110210"/>
//    <Entry link="https://source.unsplash.com/200x200/?user,profile" name="Muzzammil" content="I am a web Developer" email="muzzammil@gmail.com" phoneno="1234324234"/>
//   <div id="root"></div> */}
//     </div>
//   );

//   ReactDOM.render(
    
//     <Entry link="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60" name="Muzzammil" content="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."/>
  
//     ,document.getElementById("root")
//   );
);
}





export default App;
