import React, { useState } from 'react'






export default function AllEntries() {
 

    return (
<>
   

</>
  )
}
